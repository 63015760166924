// Libraries
import * as React from "react";
import { CSSTransition } from "react-transition-group";

// UITK
import { classNames } from "@egds/react-core/utils";
import { EGDSSpacing } from "@egds/react-core/spacing";

// Blossom Components
import { FlexClickTracker } from "components/utility/analytics/FlexClickTracker/FlexClickTracker";
import { EGDSPrimaryButton, EGDSButtonSize } from "@egds/react-core/button";

interface StickyCTAMobileProps extends React.HTMLAttributes<HTMLDivElement> {
  isSticky: boolean;
  moduleName: string;
  linkName?: string;
  submitButtonText: string;
  scrollTarget: React.RefObject<HTMLElement>;
  expandWizard?: () => void;
}

const ButtonTracked = FlexClickTracker(EGDSPrimaryButton);

/**
 * Sticky Call to Action button for the Wizard. When you click it brings focus to Wizard
 *
 * @param {boolean} isSticky - Determines if the Call to Action is visible or not
 * @param {string} moduleName - The module parent name
 * @param {string} submitButtonText - The button label text
 * @param {React.RefObject<HTMLElement>} scrollTarget - The reference to the target element that brings you into it
 */
export const StickyCTAMobile: React.FC<StickyCTAMobileProps> = ({
  moduleName,
  linkName,
  submitButtonText,
  className,
  isSticky,
  scrollTarget,
  expandWizard,
  ...htmlDivHTMLProps
}: StickyCTAMobileProps) => {
  function scrollTo() {
    const element = scrollTarget.current && scrollTarget.current && scrollTarget.current.parentElement;

    if (element) {
      element.scrollIntoView({
        block: "start",
        behavior: "smooth",
      });
      element.focus();
    }

    if (expandWizard) {
      expandWizard();
    }
  }

  return (
    <CSSTransition in={isSticky} timeout={300} classNames="slide-in" mountOnEnter unmountOnExit>
      <EGDSSpacing padding="three">
        <div className={classNames("StickyCTAMobile", className)} {...htmlDivHTMLProps}>
          <ButtonTracked
            tag="button"
            rfrr="hotel.wizard.cta"
            linkName={linkName || "Call to action click event in the wizard"}
            moduleName={moduleName}
            className="wizard-cta-btn"
            size={EGDSButtonSize.SMALL}
            isFullWidth
            data-testid="wizard-cta-button"
            onClick={scrollTo}
          >
            {submitButtonText}
          </ButtonTracked>
        </div>
      </EGDSSpacing>
    </CSSTransition>
  );
};

StickyCTAMobile.displayName = "StickyCTAMobile";

export default StickyCTAMobile;
